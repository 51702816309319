import Vue from 'vue'
import App from './App.vue'
import VueMeta from 'vue-meta'
import VueRouter from "vue-router";
import Admin from "./components/Admin"

import './styles/parallax.css'
import './styles/sharing-buttons.css'
import './styles/inputs.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import HomePage from "./components/HomePage";

String.prototype.capitalize = function() {
  return this.charAt(0).toUpperCase() + this.slice(1)
}

Vue.use(VueRouter)
Vue.use(VueMeta)
Vue.config.productionTip = false


const router = new VueRouter({
  mode: 'history',
  base: __dirname,
  routes: [
    { path: '/admin', component: Admin },
    { path: '/', component: HomePage },
  ]
})

new Vue({
  el: '#app',
  router,
  render: h => h(App)
})

