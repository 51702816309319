<template>
    <div v-if="!isDisabled" :class="`btn ${color}`" @click="$emit('on-click')">
        <span>{{value}}</span>
    </div>
    <div v-else :class="`btn ${color} disabled`">
        <span>{{value}}</span>
    </div>
</template>

<script>
    export default {
        name: 'Button',
        props: {
            value: String,
            wide: Boolean,
            disabled: Boolean,
            color: String
        },
        data() {
            return {
                isWide: this.wide,
                isDisabled: this.disabled
            }
        },
        watch: {
            disabled(value) {
                this.isDisabled = value
            }
        }
    }
</script>

<style scoped>
    :root {
        --primary-color:white;
        --primary-text-color: black;

        --secondary-color: white;
        --secondary-text-color: black;

        --link-color: green;
        --link-pressed-color: darkgreen;

        --green: #388e3c;
        --dark-gray: #433F3D;
        --red: #db4437;
    }

    .btn {
        position: relative;

        display: block;
        padding: 0;

        overflow: hidden;

        border-width: 0;
        outline: none;
        border-radius: 2px;
        box-shadow: 0 1px 4px rgba(0, 0, 0, .6);

        background-color: #43a047;
        color: #ecf0f1 !important;

        transition: background-color .3s;

        cursor: pointer;
        text-align: center;
    }

    .btn.wide {
        width: 100%;
    }

    .btn:hover, .btn:focus {
        background-color: #388e3c;
    }

    .btn > * {
        position: relative;
    }

    .btn span {
        font-size: medium;
        display: block;
        padding: 10px 22px;
    }

    .btn:before {
        content: "";

        position: absolute;
        top: 50%;
        left: 50%;

        display: block;
        width: 0;
        padding-top: 0;

        border-radius: 100%;

        background-color: rgba(236, 240, 241, .3);

        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    .btn:active:before {
        width: 120%;
        padding-top: 120%;

        transition: width .2s ease-out, padding-top .2s ease-out;
    }

    .btn.gray {
        background-color: #444;
    }

    .btn.gray:hover, .btn.gray:focus {
        background-color: #333;
    }

    .btn.orange {
        background-color: #f57c00;
    }

    .btn.orange:hover, .btn.orange:focus {
        background-color: #ef6c00;
    }

    .btn.red {
        background-color: #db4437;
    }

    .btn.red:hover, .btn.red:focus {
        background-color: #c4372a;
    }

    .btn.disabled {
        background-color: rgba(0, 0, 0, 0.3);;
    }

    .btn.disabled:active:before {
        width: 100%;
        padding-top: 100%;

        transition: width .2s ease-out, padding-top .2s ease-out;
    }


</style>