<template>
    <div id="app">
       <router-view />
    </div>
</template>

<script>

    export default {
        name: 'App',
        components: {},
        metaInfo: {
            meta: [
                {property: 'og:title', ontent: 'Moun\'Art - Artisan du rêve'},
                {property: 'og:url', content: "https://www.mounart.fr"},
                {property: 'og:type', content: "website"},
                {property: 'og:description', content: "Peinture de motifs personnalisés sur tout type de support"},
                {property: 'og:image', content: "https://www.mounart.fr/logo.png"}
            ]
        }
    }
</script>

<style lang="scss">
    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
    }

    html, body, #app {
        height: 100%;
    }

    body {
        margin: 0;
    }

    .clickable {
        cursor: pointer;
    }

    .mobile-only {
        display: none;
    }

    @media only screen and (max-width: 737px) {
        .mobile-hide {
            display: none;
        }
    }

</style>
