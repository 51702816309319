<template>
    <Modal :display="shouldDisplay" @on-close="close">
        <template v-slot:header>
            <h1>Votre panier de course est bien rempli</h1>
        </template>
        <template v-slot:content>
            <ul class="checkout-recap">
                <li v-for="product in items" v-bind:key="`cart-item-${product.id}`">
                    <div class="product-description">
                        <img :src="product.imageSrc" :alt="product.imageAlt">
                        <div>{{product.name}}</div>
                    </div>
                    <div class="price">{{product.price}}€</div>
                </li>
            </ul>
            <div class="total-container">
                Total : {{total}}€
            </div>
            <div class="form-container">
                <div class="form-hook">
                    <h2>J'aurais besoin d'en savoir un peu plus sur vous</h2>
                </div>
                <div v-if="error" class="error-block">{{error}}</div>
                <div class="form">
                    <div class="field-container">
                        <label>Votre prénom
                            <input v-model="firstName" class="field" name="firstName" type="text"/>
                        </label>
                    </div>
                    <div class="field-container">
                        <label>Votre nom
                            <input v-model="lastName" class="field" name="lastName" type="text"/>
                        </label>
                    </div>
                    <div class="field-container">
                        <label>Votre email
                            <input v-model="email" class="field" name="email" type="email" placeholder="nom@domaine.net"/>
                        </label>
                    </div>
                    <div class="field-container">
                        <label>Votre téléphone (fixe ou portable)
                            <input v-model="phone" class="field" name="phone" type="tel" placeholder="0123456789"/>
                        </label>
                    </div>
                </div>
            </div>
        </template>
        <template slot="footer">
            <Button value="Réserver les produits" @on-click="checkout"/>
        </template>
    </Modal>
</template>

<script>
    import Modal from "./Modal";
    import Button from "./Button";

    export default {
        name: 'CartModal',
        components: {Modal, Button},
        props: {
            display: Boolean,
            cart: Array
        },
        data() {
            return {
                items: this.cart,
                shouldDisplay: this.display,
                error: undefined,
                firstName: undefined,
                lastName: undefined,
                phone: undefined,
                email: undefined
            }
        },
        computed: {
          total() {
              let totalPrice = this.items.map(i => i.price).reduce((a, b) => {
                  return parseFloat(a) + parseFloat(b)
              }, 0)
              return Math.round(totalPrice * 100) / 100
          }
        },
        watch: {
            display(value) {
                this.shouldDisplay = value
            },
            cart(value) {
                this.items = value
            }
        },
        methods: {
            checkEmail(email) {
                let b = new RegExp(/^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/).test(email);
                console.log('b : ' + JSON.stringify(b))
                return b
            },
            checkPhone(phone) {
                return new RegExp(/^((\+)33|0)[1-9](\d{2}){4}$/).test(phone)
            },
            checkout() {
                if (!this.firstName || !this.lastName || !this.email || !this.phone) {
                    this.error = "Tous les champs sont obligatoire"
                } else if (!this.checkEmail(this.email)) {
                    this.error = "le format de votre email est invalide"
                } else if (!this.checkPhone(this.phone)) {
                    this.error = "le format de votre téléphone est invalide"
                } else {
                    this.$emit('on-checkout', {
                        email: this.email,
                        phone: this.phone,
                        name: `${this.firstName.capitalize()} ${this.lastName.capitalize()}`,
                        cart: this.items
                    })
                }
            },
            close() {
                this.$emit('on-close')
            }

        }

    }
</script>

<style lang="scss">

    .total-container {
        text-align: right;
        font-size: x-large;
        font-weight: 600;
        margin-bottom: 1em;
    }

    .checkout-recap {
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 0;

        li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 1em;

            .product-description {
                display: flex;
                align-items: center;

                img {
                    height: 70px;
                    width: auto;
                }

                :last-child {
                    font-size: x-large;
                    padding-left: 1em;
                }
            }

            .price {
                font-size: x-large;
            }
        }
    }


    @media only screen and (max-width: 737px) {

        .total-container {
            text-align: right;
            font-size: x-large;
            font-weight: 600;
            margin-bottom: 1em;
        }

        .checkout-recap {

            li {
                .product-description {

                    img {
                        height: 50px;
                        width: auto;
                    }

                    :last-child {
                        font-size: large;
                        padding-left: 1em;
                    }
                }

                .price {
                    font-size: large;
                }
            }
        }
    }
</style>