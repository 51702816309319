<template>
    <div v-if="show" id="myModal" class="modal">
        <div class="modal-content">
            <span @click="close" class="close">&times;</span>
            <div class="modal-header">
                <slot name="header"></slot>
            </div>
            <div class="content">
                <slot name="content"></slot>
            </div>
            <div class="footer">
                <slot name="footer" :events="{ submit }"></slot>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: "Modal",
        props: {
            display: Boolean
        },
        data() {
            return {
                show: false
            }
        },
        watch: {
            display(value) {
                this.show = value
            }
        },
        methods: {
            close() {
                this.$emit('on-close')
            },
            submit() {
                this.$emit('on-submit')
            }
        }
    }
</script>

<style lang="scss" scoped>
    /* The Modal (background) */
    .modal {
        position: fixed; /* Stay in place */
        display: flex;
        z-index: 10; /* Sit on top */
        left: 0;
        top: 0;
        width: 100%; /* Full width */
        height: 100%; /* Full height */
        background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
        align-items: center;
        justify-content: center;
    }

    /* Modal Content/Box */
    .modal-content {
        background-color: #fefefe;
        margin: 15% auto; /* 15% from the top and centered */
        padding: 20px;
        border: 1px solid #888;
        width: 80%; /* Could be more or less, depending on screen size */
        height: 80%;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        position: relative;
    }

    /* The Close Button */
    .close {
        color: #aaa;
        float: right;
        font-size: 28px;
        font-weight: bold;
        position: absolute;
        right: 30px;
    }

    .close:hover,
    .close:focus {
        color: black;
        text-decoration: none;
        cursor: pointer;
    }

    .content {
        height: 100%;
    }

    .modal-header {
       padding-bottom: 1em;
    }

    @media only screen and (max-width: 737px) {
        .modal-content {
            margin: 0;
            padding: 20px;
            border: 0;
            width: 100%;
            height: 100%;
        }
    }
</style>