<template>
    <footer>
        <div class="footer-container">
            <div class="footer-header">Moun'Art</div>
            <div class="footer-content">
                <div>Contactez moi par mail : sylvianei@yahoo.fr</div>
                <div>ou par téléphone : 06 71 55 21 16</div>
            </div>
            <div class="footer-footer">
                Site web réalisé par <a href="https://www.soonagency.fr">Soon Web Agency</a>
            </div>
        </div>

    </footer>
</template>

<script>
    export default {
        name: "Footer"
    }
</script>

<style lang="scss" scoped>

    footer {
        position: relative;
        font-size: 16px;
        font-weight: 300;
        padding: 45px 0 0px;
        background: #333333;
        color: #fff;

        a {
            color: white;
        }

        .footer-container {
            width: 1200px;
            margin: auto;

            display: flex;
            flex-direction: column;

            .footer-content {
                padding: 0 2em 0 2em;
            }

            .footer-footer {
                display: flex;
                justify-content: space-between;
                padding: 17px 5px;
                margin-top: 2em;
                border-top: 1px solid #fff;

                a {
                    display: block;
                }
            }
        }

        .footer-header {

            font-size: 28px;
            font-weight: bold;
            padding-bottom: 1.5em;
            text-transform: uppercase;
        }
    }

    @media only screen and (max-width: 1260px) {

        footer {

            padding: 2em 2em 1em 2em;

            .footer-container {
                width: 100%;

                .footer-content {
                    padding: 0;
                }
            }
        }
    }

    @media only screen and (max-width: 737px) {

        footer {

            padding: 1em;

            .footer-header {
                padding-bottom: 1em;
            }
        }

    }

</style>