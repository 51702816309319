<template>
    <div :class="classes" @click="onClick">
        <i :data-count="value" :style="{fontSize: 'x-large'}" :class="`my-float badge ${iconClass}`"></i>
    </div>
</template>

<script>
    export default {
        name: 'FloatingButton',
        props: {
            iconClass: String,
            count: Number,
            bouncing: Boolean
        },
        data() {
            return {
                value: this.count,
                classes: this.bouncing ? "float bouncing" : "float"
            }
        },
        watch: {
            count(value) {
                this.value = value
            },
            bouncing(value) {
                this.classes = value ? "float bouncing" : "float"
            }
        },
        methods: {
            onClick() {
                this.$emit('on-click')
            }
        }
    }
</script>

<style lang="scss" scoped>

    .float {
        position:fixed;
        width:60px;
        height:60px;
        bottom:40px;
        right:40px;
        background-color:#0C9;
        color:#FFF;
        border-radius:50px;
        text-align:center;
        box-shadow: 2px 2px 3px #999;
        cursor: pointer;
    }

    .float.bouncing {
        animation: bounce 0.35s ease infinite alternate;
    }

    @keyframes bounce {
        from {transform: translateY(0);}
        to   {transform: translateY(-20px);}
    }

    .my-float {
        margin-top:22px;
    }

    .badge:after{
        content:attr(data-count);
        position: absolute;
        background: red;
        height: 1rem;
        top: 0.8rem;
        right: 0.6rem;
        width: 1rem;
        text-align: center;
        line-height: 1rem;
        border-radius: 50%;
        color: white;
        font-size: 11px;
        border: 1px solid red;
    }
</style>