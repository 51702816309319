<template>
    <div class="who-am-i">
        <div class="img-rounded"><img :src="require('../assets/sylviane_portrait_thumb.jpg')" /></div>
        <div class="who-am-i-description">
            <h1>Qui suis-je ?</h1>
            <div>
                <p>Une passionnée des arts plastiques, boulimique d'apprendre ce que je ne sais pas encore faire.. et de transmettre aussi.</p>
                <p>Après la peinture à l'huile, j’ai découvert il y a deux ans la peinture porcelaine, et c'est le coup de foudre !</p>
                <p>La technique m'a enthousiasmée, le rendu aussi et je me suis lancée...</p>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'WhoAmI'
    }
</script>

<style>

    .img-rounded img {
        height: auto;
        width: 180px;
        max-width: 180px;
        border-radius: 50%;
    }

    .who-am-i {
        display: flex;
        flex-direction: row;

    }

    .who-am-i-description {
        margin-left: 3em;
    }


    @media only screen and (max-width: 737px) {
        .who-am-i {
            flex-direction: column;
            padding: 1em;
            align-items: center;
        }

        .who-am-i-description {
            margin-left: 0;
        }
    }
</style>