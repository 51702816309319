<template>
    <Modal :display="shouldDisplay" @on-close="close">
        <template v-slot:header>
            <h2>Votre commande a bien été réservée</h2>
        </template>
        <template v-slot:content>
            <p>Merci pour votre commande, Je vous recontacterai très prochainement pour convenir des modalitées de
                paiement et d'expédition</p>
        </template>
        <template v-slot:footer>
            <a class="resp-sharing-button__link"
               href="https://facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.mounart.fr" target="_blank"
               rel="noopener noreferrer" aria-label="Partager sur Facebook">
                <div class="resp-sharing-button resp-sharing-button--facebook resp-sharing-button--large">
                    <div aria-hidden="true" class="resp-sharing-button__icon resp-sharing-button__icon--solid">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path d="M18.77 7.46H14.5v-1.9c0-.9.6-1.1 1-1.1h3V.5h-4.33C10.24.5 9.5 3.44 9.5 5.32v2.15h-3v4h3v12h5v-12h3.85l.42-4z"/>
                        </svg>
                    </div>
                    Partager sur Facebook
                </div>
            </a>
        </template>
    </Modal>
</template>

<script>
    import Modal from "./Modal";

    export default {
        name: 'CheckoutSuccessModal',
        components: {Modal},
        props: {
            display: Boolean
        },
        data() {
            return {
                shouldDisplay: this.display
            }
        },
        watch: {
            display(value) {
                this.shouldDisplay = value
            }
        },
        methods: {
            close() {
                this.$emit('on-close')
            }

        }

    }
</script>

<style lang="scss">

    .total-container {
        text-align: right;
        font-size: x-large;
        font-weight: 600;
        margin-bottom: 1em;
    }

    .checkout-recap {
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 0;

        li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 1em;

            .product-description {
                display: flex;
                align-items: center;

                img {
                    height: 70px;
                    width: auto;
                }

                :last-child {
                    font-size: x-large;
                    padding-left: 1em;
                }
            }

            .price {
                font-size: x-large;
            }
        }
    }


    @media only screen and (max-width: 737px) {

        .total-container {
            text-align: right;
            font-size: x-large;
            font-weight: 600;
            margin-bottom: 1em;
        }

        .checkout-recap {

            li {
                .product-description {

                    img {
                        height: 50px;
                        width: auto;
                    }

                    :last-child {
                        font-size: large;
                        padding-left: 1em;
                    }
                }

                .price {
                    font-size: large;
                }
            }
        }
    }
</style>