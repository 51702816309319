<template>
    <div :style="{height: '100%'}">
        <div class="parallax-background"
             :style="{ height: '100%', backgroundImage: `url(${require(`@/assets/noel2020/sous_de_plat_noel.jpg`)})`}">
            <div class="parallax-title" :style="{top: '20%'}">Moun'Art</div>
            <div class="parallax-subtitle" :style="{top: '30%'}">Artisan peintre</div>
            <div class="parallax-subtitle" :style="{top: '36%', maxWidth: '30%', textAlign: 'left', fontSize: '20px'}">Création personnalisée sur tout support</div>

        </div>
        <div class="top-banner">
            <h1>Erratum</h1>
            <div>
                <p>
                    Une erreur s'est glisée dans le journal de la ville de Thiais de Décembre 2020 !<br/>
                    Vous pouvez me joindre aux coordonnées suivantes :
                </p>
                <ul class="contacts">
                    <li>par mail : sylvianei@yahoo.fr</li>
                    <li>ou par téléphone : <strong>06 71 55 21 16</strong></li>
                </ul>
            </div>

        </div>
        <div class="static-element" :style="{ backgroundImage: `url(${require(`@/assets/silver-ribbon-and-tree-decorations.jpg`)})`,
         backgroundAttachment: 'fixed',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        }">
            <div class="static-text">
                <h1>Découvrez toutes nos pièces</h1>
                <p>Pour ce marché de Noël 2020 de Thiais si particulier, je vous propose un ensemble de pièces uniques : des mugs, des services à dessert ou à café, des plat avec des motifs très variés pour que chacun y trouve son compte.</p>
                <p>Et si votre imagination est aussi fertile que la mienne, vous pouvez bien entendu me proposer vos propres exigences : portraits, paysages, animaux préférés.</p>
                <p> De même, tous les modèles sont personnalisables, soit avec un petit mot, soit avec un prénom.</p>
                <p>N'hésitez pas à me joindre soit par mail, soit par téléphone. A défaut de stand, je vous propose une livraison à la porte de votre domicile, avec tous les gestes barrières qui s’imposent pour vous montrer le(s) modèle(s) qui vous attirent</p>
                <p>Je n'ai plus qu'à vous souhaiter une bonne visite sur mon site.</p>
            </div>
            <div class="gallery-container">
                <div class="gallery-list">
                    <div v-for="product in products" class="gallery-item" :key="product.id">
                        <div class="gallery-item-container">
                            <div class="img-container">
                                <img :class="selectedProduct.id === product.id ? 'selected ' : ''"
                                     :src="product.imageSrc" :alt="product.imageAlt"
                                     @click="displayProduct(product)" />
                            </div>
                            <div v-if="selectedProduct.id === product.id" class="product-description">
                                <h3>{{product.name}}</h3>
                                <h5><span>{{product.price}}€</span></h5>
                                <p>{{product.description}}</p>

                                <span>{{message}}</span>
                                <Button v-if="parseInt(product.booked) === 0" value="Réserver" @on-click="addToCart(product)"/>
                                <Button v-else-if="isProductInCart(product)" value="Le produit a été ajouté à votre panier" disabled />
                                <Button v-else value="Ce produit est réservé pendant 24h" disabled/>
                            </div>
                            <div v-if="selectedProduct.id !== product.id" class="overlay mobile-hide">Voir la description</div>
                            <div v-if="selectedProduct.id !== product.id" class="show-more mobile-only"><i class="fas fa-search"></i></div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="soldProducts.length > 0" class="static-text">
                <h1>Pièces déjà vendu</h1>
            </div>
            <div v-if="soldProducts.length > 0" class="gallery-container">
                <div class="gallery-list">
                    <div v-for="product in soldProducts" class="gallery-item" :key="product.id">
                        <div class="gallery-item-container">
                            <div class="img-container">
                                <img :src="product.imageSrc" :alt="product.imageAlt" />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div class="parallax-background"
             :style="{ height: '600px', backgroundImage: `url(${require(`@/assets/sylviane_at_work.jpg`)})`}">
        </div>

        <div class="static-element">
            <WhoAmI />
        </div>
        <Footer/>

        <FloatingButton v-if="cart.length > 0" :count="cart.length" icon-class="fas fa-shopping-cart"
                        @on-click="showCart" :bouncing="bouncing" />

        <CartModal :display="displayCart" :cart="cart"
                   @on-close="displayCart = false"
                   @on-checkout="checkout"/>

        <CheckoutSuccessModal :display="displaySuccessCheckout"
                              @on-close="displaySuccessCheckout = false"/>
    </div>
</template>

<script>
    import Button from "./Button";
    import axios from 'axios'
    import _ from 'lodash'
    import FloatingButton from "./FloatingButton";
    import CartModal from "./CartModal";
    import CheckoutSuccessModal from "./CheckoutSuccessModal";

    import Footer from "./Footer";
    import WhoAmI from "./WhoAmI";

    export default {
        name: 'App',
        metaInfo: {
            meta: [
                {property: 'og:title', ontent: 'Moun\'Art - Artisan du rêve'},
                {property: 'og:url', content: "https://www.mounart.fr"},
                {property: 'og:type', content: "website"},
                {property: 'og:description', content: "Peinture de motifs personnalisés sur tout type de support"},
                {property: 'og:image', content: "https://www.mounart.fr/logo.png"}
            ]
        },
        components: {WhoAmI, CheckoutSuccessModal, CartModal, FloatingButton, Button, Footer},
        data() {
            return {
                cart: [],
                selectedProduct: {},
                products: [],
                soldProducts: [],
                displayCart: false,
                displaySuccessCheckout: false,
                message: undefined,
                bouncing: false
            }
        },
        methods: {
            displayProduct(product) {
                if (product.name === this.selectedProduct.name) {
                    this.selectedProduct = {}
                } else {
                    this.selectedProduct = product
                }
            },
            addToCart(product) {
                if (this.cart.length === 0) {
                    this.bouncing = true
                }
                let tmp = _.cloneDeep(this.cart)
                product.booked = 1
                tmp.push(product)
                this.cart = tmp
            },
            isProductInCart(product) {
                console.log('product : ' + JSON.stringify(product))
                console.log('this.cart : ' + JSON.stringify(this.cart))
                let number = this.cart.findIndex(c => c.id == product.id);
                console.log('number : ' + JSON.stringify(number))
                return number !== -1
            },
            showCart() {
                this.displayCart = true
                this.bouncing = false
            },
            checkout(body) {
                let config = {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json',
                    }
                }

                let host = process.env.NODE_ENV === 'production'
                    ? 'https://www.mounart.fr'
                    : "http://localhost/mounart"

                axios.post(`${host}/products.php/checkout`, body, config).then(() => {
                    this.cart = []
                    this.displayCart = false
                    this.displaySuccessCheckout = true
                }).catch(error => {
                    console.log('error in fetching : ' + JSON.stringify(error))
                })
            }
        },
        created() {

            let config = {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                }
            }

            let host = process.env.NODE_ENV === 'production'
                ? 'https://www.mounart.fr'
                : "http://localhost/mounart"

            axios.get(`${host}/products.php`, config).then(response => {
                this.products = response.data.filter(p => p.sold == 0)
                this.soldProducts = response.data.filter(p => p.sold == 1)
            }).catch(error => {
                console.log('error in fetching : ' + JSON.stringify(error))
            })
        }
    }
</script>

<style lang="scss">

    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
    }

    html, body, #app {
        height: 100%;
    }

    body {
        margin: 0;
    }

    .top-banner {
        position: relative;
        background-color: #e53935;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 1em;

        .contacts {
            max-width: 300px;
            padding-top: 0.5em;
            text-align: left;
        }

        div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        p {
            margin: 0;
        }

        ul {
            margin: 0;
        }
        h1 {
            margin: 0;
            color: black;
        }
    }

    .gallery-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .gallery-list {
        display: flex;
        flex-wrap: wrap;
    }

    .gallery-item {
        flex-grow: 1;
        margin: 1px;
        position: relative;
        background-color: #E3E4E9;

        .overlay {
            opacity: 0;
            background: rgba(255, 236, 236, 0.75);;
            padding: 0.5rem;
            font-size: 22px;
            position: absolute;
            margin-top: 1rem;
            bottom: 0;
            right: 0;
            text-align: right;
            cursor: pointer;
        }
    }

    .gallery-item {
        .img-container {
            position: relative;
            img {

                height: 350px;
                object-fit: cover;
                max-width: 100%;
                min-width: 100%;
                vertical-align: bottom;
                image-orientation: none;
                cursor: pointer;
            }
        }
    }

    .gallery-item img.selected {
        width: 50%;
        height: 600px;
    }

    .gallery-item-container {
        display: flex;
        flex-direction: row;

        .product-description {
            padding: 0 6em 2em 2em;
            opacity: 0.9;
            border: 1px solid #d8d8d8;
        }

        div {
            flex: 1;
        }
    }

    @media (hover: hover) {
        .gallery-item .overlay {
            transition: opacity 0.2s;
        }
        .gallery-item:hover .overlay {
            opacity: 1;
            transition: opacity 0.2s;
        }
    }

    @media only screen and (max-width: 737px) {

        .gallery-item {
            flex-grow: 1;
            margin: 0;
            padding-bottom: 1em;

            .overlay {
                display: none;
            }

            .show-more {
                display: block;
                position: absolute;
                color: #c3bdbd;
                z-index: 10;
                bottom: 10px;
                right: 15px;
                font-size: larger;
            }
        }

        .gallery-item img.selected {
            width: 50%;
            height: 350px;
        }

        .gallery-item-container {
            flex-direction: column;
            position: relative;

            .product-description {
                padding: 0 2em 2em 2em;
                border: 0;
            }
        }
    }

</style>
