<template>
    <div class="admin-page">
        <h1>Moun'Art Admin</h1>
        <div v-if="!loggedIn" class="form-container">
            <div v-if="error" class="error-block">{{error}}</div>
            <label class="field-container">Login
                <input type="text" v-model="email" />
            </label>
            <label class="field-container">Mot de passe
                <input type="password" v-model="password" />
            </label>
            <Button value="Se connecter" @on-click="login"/>
        </div>
        <div v-else>
            <div class="clickable" @click="logOut">Se déconnecter</div>
            <h2>Commandes en attente</h2>
            <table :style="{width: '100%'}">
                <thead :style="{textAlign: 'right', backgroundColor: '#DDDDDD'}">
                    <tr>
                        <th>Commande #</th>
                        <th>Date commande</th>
                        <th>Nom client</th>
                        <th>Email</th>
                        <th>Téléphone</th>
                        <th>Annulée</th>
                        <th>Expédiée</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody :style="{textAlign: 'right'}">
                <tr v-if="pendingOrders.length === 0"><td class="no-data">Aucune nouvelle commandes</td></tr>
                <tr class="order-item" v-for="order in pendingOrders" v-bind:key="`pending-${order.id}`">
                    <td class="clickable" @click="showOrderDetails(order)">{{order.id}}</td>
                    <td>{{order.orderDate}}</td>
                    <td>{{order.customer}}</td>
                    <td>{{order.email}}</td>
                    <td>{{order.phone}}</td>
                    <td>{{order.canceled == 1 ? 'Oui' : 'Non'}}</td>
                    <td>{{order.shipped == 1 ? 'Oui' : 'Non'}}</td>
                    <td :style="{display: 'flex'}">
                        <Button value="Détails" @on-click="showOrderDetails(order)" />
                    </td>
                </tr>

                </tbody>
            </table>

            <h2>Commandes à expédier</h2>
            <table :style="{width: '100%'}">
                <thead :style="{textAlign: 'right', backgroundColor: '#DDDDDD'}">
                <tr>
                    <th>Commande #</th>
                    <th>Date commande</th>
                    <th>Nom client</th>
                    <th>Email</th>
                    <th>Téléphone</th>
                    <th>Expédiée</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody :style="{textAlign: 'right'}">
                <tr v-if="orderToShip.length === 0"><td class="no-data">Aucune commande à expédier</td></tr>
                <tr class="order-item" v-for="order in orderToShip" v-bind:key="`to-ship-${order.id}`">
                    <td>{{order.id}}</td>
                    <td>{{order.orderDate}}</td>
                    <td>{{order.customer}}</td>
                    <td>{{order.email}}</td>
                    <td>{{order.phone}}</td>
                    <td>{{order.shipped == 1 ? 'Oui' : 'Non'}}</td>
                    <td :style="{display: 'flex'}">
                        <Button v-if="order.shipped == 0" value="Expédier" @on-click="setShipped(order.id)" />
                    </td>
                </tr>
                </tbody>
            </table>

        </div>
        <Modal :display="displayOrderDetails" @on-close="displayOrderDetails = false">
            <template v-slot:header>
                <h1>Détail de la Commande</h1>
            </template>
            <template v-slot:content>
                <div class="order-details">
                    <div><span>Date de commande : </span>{{selectedOrder.orderDate}}</div>
                    <div><span>Client : </span>{{selectedOrder.customer}}</div>
                    <div><span>Email : </span>{{selectedOrder.email}}</div>
                    <div><span>Téléphone : </span>{{selectedOrder.phone}}</div>
                    <div><span>Annulé : </span>{{selectedOrder.canceled == 1 ? 'Oui' : 'Non'}}</div>
                    <div><span>Envoyé : </span>{{selectedOrder.shipped == 1 ? 'Oui' : 'Non'}}</div>
                </div>
                <ul class="checkout-recap">
                    <h2 :style="{textAlign: 'left'}">Les produits</h2>
                    <li v-for="product in selectedOrder.products" v-bind:key="`cart-item-${product.id}`">
                        <div class="product-description">
                            <img :src="product.imageSrc">
                            <div>{{product.name}}</div>
                        </div>
                        <div class="price">{{product.price}}€</div>
                    </li>
                </ul>
                <div class="total-container">
                    Total : {{selectedOrder.products.map(p => p.price).reduce((prev, curr) => {
                        return parseFloat(prev) + parseFloat(curr)
                    }, 0)}}€
                </div>
            </template>
            <template slot="footer">
                <div class="button-container">
                    <Button color="red" value="Annuler la commande" @on-click="setCanceled(selectedOrder.id)" :disabled="selectedOrder.canceled == 1"/>
                    <Button value="Valider la commande" @on-click="validateOrder(selectedOrder.id)" :disabled="selectedOrder.validated == 1"/>
                    <Button color="gray" value="Fermer sans rien faire" @on-click="displayOrderDetails = false"/>
                </div>
            </template>
        </Modal>
    </div>
</template>
<script>
    import axios from 'axios'
    import Button from "./Button";
    import Modal from "./Modal";

    export default {
        name: 'Admin',
        components: {Modal, Button},
        data() {
            return {
                email: "",
                password: "",
                error: undefined,
                loggedIn: false,
                pendingOrders: [],
                orderToShip: [],
                displayOrderDetails: false,
                selectedOrder: {}
            }
        },
        methods: {
            login() {
                console.log("logging")
                this.error = undefined

                let config = {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json',
                    }
                }

                let host = process.env.NODE_ENV === 'production'
                    ? 'https://www.mounart.fr'
                    : "http://localhost/mounart"

                axios.post(`${host}/login.php`, {email: this.email, password: this.password}, config).then(response => {

                    this.loggedIn = true
                    window.localStorage.token = response.data
                    this.fetchOrders()

                }).catch(error => {
                    this.error = error
                })
            },
            fetchOrders() {

                const {config, host } = this.getAxiosConfig()
                axios.get(`${host}/orders.php`, config).then(response => {

                    if (response.status >= 300) {
                        this.error = "Oops ca n'a pas fonctionné. Veuillez réessayer."
                    } else {
                        this.pendingOrders = this.mapProducts(response.data.filter(o => o.validated == 0))
                        this.orderToShip = this.mapProducts(response.data.filter(o => o.validated == 1))
                    }

                }).catch(error => {
                    if (this.isUnauthorized(error.message)) this.logOut()
                    this.error = error
                })
            },
            showOrderDetails(order) {
              this.selectedOrder = order
              this.displayOrderDetails = true
            },
            isUnauthorized(message) {
              return message && message.indexOf("status code 401") !== -1
            },
            mapProducts(flatOrders) {

                let mappedProducts = flatOrders.reduce(function (map, obj) {

                    let product = {
                        id: obj.productId,
                        name: obj.name,
                        price: obj.price,
                        imageSrc: obj.imageSrc
                    }
                    delete obj.name
                    delete obj.price
                    delete obj.imageSrc

                    if (!map[obj.id]) {
                        obj.products = [product]
                        map[obj.id] = obj;
                    } else {
                        let existingOrder = map[obj.id]
                        existingOrder.products.push(product)
                        map[obj.id] = existingOrder;
                    }
                    return map;
                }, {});

                return Object.keys(mappedProducts).map(key => mappedProducts[key])
            },
            setShipped(id) {
                const {config, host } = this.getAxiosConfig()
                axios.post(`${host}/orders.php/shipped`, { id }, config).then(response => {

                    if (response.status >= 300) {
                        this.error = "Oops ca n'a pas fonctionné. Veuillez réessayer."
                    } else {
                        let found = this.orderToShip.findIndex(o => o.id == id)
                        if (found !== -1) {
                            let item = this.orderToShip[found]
                            item.shipped = 1
                            this.orderToShip[found] = item
                        }
                    }

                }).catch(error => {
                    if (this.isUnauthorized(error.message)) this.logOut()
                    this.error = error
                })
            },
            setCanceled(id) {
                const {config, host } = this.getAxiosConfig()
                axios.post(`${host}/orders.php/canceled`, { id }, config).then(response => {

                    if (response.status >= 300) {
                        this.error = "Oops ca n'a pas fonctionné. Veuillez réessayer."
                    } else {
                        this.displayOrderDetails = false

                        let found = this.pendingOrders.findIndex(o => o.id == id)
                        if (found !== -1) {
                            let item = this.pendingOrders[found]
                            item.canceled = 1
                            this.pendingOrders[found] = item
                        }
                    }


                }).catch(error => {
                    if (this.isUnauthorized(error.message)) this.logOut()
                    this.error = error
                })
            },
            validateOrder(id) {
                console.log('id : ' + JSON.stringify(id))
                console.log('this.pendingOrders. : ' + JSON.stringify(this.pendingOrders))
                const {config, host } = this.getAxiosConfig()
                axios.post(`${host}/orders.php/validate`, { id }, config).then(response => {
                    if (response.status >= 300) {
                        this.error = "Oops ca n'a pas fonctionné. Veuillez réessayer."
                    } else {
                        this.displayOrderDetails = false
                        let found = this.pendingOrders.findIndex(o => o.id == id)
                        console.log('found : ' + JSON.stringify(found))
                        if (found != -1) {
                            let item = this.pendingOrders[found]
                            item.validated = 1
                            item.canceled = 0
                            this.pendingOrders.splice(found, 1)
                            this.orderToShip.push(item)
                        }
                    }

                }).catch(error => {
                    if (this.isUnauthorized(error.message)) this.logOut()
                    this.error = error
                })
            },
            getAxiosConfig() {
                let config = {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'X-Token': window.localStorage.token
                    }
                }
                let host = process.env.NODE_ENV === 'production'
                    ? 'https://www.mounart.fr'
                    : "http://localhost/mounart"
                return {
                    config,
                    host
                }
            },
            logOut() {
                this.loggedIn = false
                delete window.localStorage.token
            }
        },
        created() {
            if (window.localStorage.token) {
                this.loggedIn = true
                this.fetchOrders()
            }
        }
    }
</script>

<style lang="scss" scoped>

    .admin-page {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-left: 4em;
        padding-right: 4em;
    }
    .order-container {
        display: flex;
        flex-direction: column;

        .order-item {
            display: flex;
            width: 100%;
            border: 1px solid #777;
            padding: 1em;
        }
    }

    table {
        border-spacing: 0;
        th {
            padding: 0.5em 0.5em 0.5em 0.5em;
            text-align: left;
        }

        td {
            text-align: left;
            padding: 1em 1em 1em 0.5em;

            .btn {
                margin-right: 1em;
            }


        }

        .clickable {
            text-decoration: underline;
            color: #2d4373;
        }

        .clickable:hover {
            text-decoration: underline;
            color: #3a80c1;
        }
    }

    .order-details {
        display: flex;
        flex-wrap: wrap;
        text-align: left;
        div {
            padding: 0.5em 0 0 0;
            flex: 1;
            min-width: 40%;

            span {
                font-weight: bold;
            }
        }
    }

    .button-container {
        display: flex;
        justify-content: flex-end;
        div:not(:last-child) {
            margin-right: 1em;
        }
    }
    .form-container {
        display: flex;
        justify-content: center;
        width: 500px;
    }

    .total-container {
        font-size: large;
        margin-bottom: 0.5em;
    }

    .checkout-recap {

        li {
            margin-bottom: 0.5em;

            .product-description {

                img {
                    height: 50px;
                }

                :last-child {
                    font-size: large;
                    padding-left: 0.5em;
                }
            }

            .price {
                font-size: large;
            }
        }
    }


    @media only screen and (max-width: 737px) {

        .total-container {
            font-size: large;
            margin-bottom: 0.5em;
        }

        .checkout-recap {

            li {
                .product-description {

                    img {
                        height: 50px;
                        width: auto;
                    }

                    :last-child {
                        font-size: large;
                        padding-left: 1em;
                    }
                }

                .price {
                    font-size: large;
                }
            }
        }
    }
</style>